import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {
			id: localStorage.id,
			name: localStorage.name,
			email: localStorage.email,
			token: localStorage.token,
		},
		ui: {
			flush: 0,
			material_modal: false,
			customer_modal: false,
		},
	},
});
