<template>
	<section>
		<div class="mt-6 mb-5">
			<div class="columns ">
				<div class="column has-text-left">
					<h1 class="title p-0 m-0">{{ title }}</h1>
				</div>

				<b-taglist attached>
					<b-tag :type="modiType(modi)">
						<h6>{{ $store.state.user.name }}</h6>
					</b-tag>

					<b-tag :type="modiTypeEx(modi)">
						<b-dropdown aria-role="list">
							<template #trigger="{ active }">
								<b-icon :icon="active ? 'menu-up' : 'menu-down'" size="is-small"> </b-icon>
							</template>

							<b-dropdown-item class="has-text-left" @click="profile">Profil</b-dropdown-item>
							<b-dropdown-item class="has-text-left" @click="logout">Çıkış Yap</b-dropdown-item>
						</b-dropdown>
					</b-tag>
				</b-taglist>
			</div>

			<h6 class="is-italic">
				Görüntüleme modu:
				<b-tag :type="modiType(modi)">
					{{ modiText(modi) }}
				</b-tag>
			</h6>
		</div>
	</section>
</template>

<script>
export default {
	name: "TitleBar",
	props: {
		title: String,
		modi: String,
	},
	computed: {
		modiType() {
			return (modi) => (modi == "show" ? "is-info is-light" : modi == "edit" ? "is-warning is-light" : modi == "add" ? "is-success is-light" : modi == "ref" ? "is-success is-light" : "is-light");
		},
		modiTypeEx() {
			return (modi) => (modi == "show" ? "is-info" : modi == "edit" ? "is-warning" : modi == "add" ? "is-success" : modi == "ref" ? "is-success" : "is-light");
		},
		modiText() {
			return (modi) => (modi == "show" ? "Salt Okunur" : modi == "edit" ? "Düzenle" : modi == "add" ? "Ekle" : modi == "ref" ? "Referans İle Ekle" : "Normal");
		},
	},
	data: () => ({}),
	created() {},
	methods: {
		profile() {
			this.$router.push({ path: "/profil" });
		},
		logout() {
			localStorage.clear();
			this.$store.state.user = {};
		},
	},
};
</script>
