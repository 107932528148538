<template>
	<b-autocomplete
		placeholder="Kullanıcı seçiniz.."
		field="name"
		icon="search"
		size="is-small"
		v-model="value.name"
		:data="users"
		:loading="$apollo.queries.readUser.loading"
		:disabled="disabled"
		@select="users_select"
		@typing="users_read"
		@focus="users_focus"
		clearable
		open-on-focus
	>
		<template slot="empty">Sonuç bulunamadı.</template>

		<template slot-scope="props">
			<div class="media">
				<div class="media-content">
					<small>
						{{ props.option.name }}
					</small>
				</div>
			</div>
		</template>
	</b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "UserAutofill",
	props: ["value", "disabled"],
	data: () => ({
		users: [],
	}),
	methods: {
		users_focus() {
			this.users_read();
		},
		users_read() {
			this.$apollo.queries.readUser.start();
		},
		users_select(opt) {
			Object.assign(this.value, opt);
		},
	},
	apollo: {
		readUser: {
			query: gql`
				query readUsers($config: String) {
					readUsers(config: $config) {
						id
						name
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 5,
						sorts: [],
						filters: {
							name: this.value.name ? "%" + this.value.name + "%" : "%",
						},
					}),
				};
			},
			update(data) {
				this.users = data.readUsers;
			},
		},
	},
};
</script>
