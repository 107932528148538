<template>
    <section>
        <b-menu class="sidebar ml-5" activable>
            <img class="maxwi" src="@/assets/logo.png" />
            <b-menu-list label="Arama" v-show="0">
                <b-input
                    size="is-small"
                    class="mx-5"
                    icon-left="magnify"
                    placeholder="Ara.."
                    v-model="filter"
                    :icon-right="filter ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="filter = ''"
                >
                </b-input>
            </b-menu-list>

            <b-menu-list label="Menu">
                <b-menu-item label="Anasayfa" icon="home" to="/" tag="router-link"> </b-menu-item>
                <b-menu-item v-for="group in menu.groups" :key="group.label" :label="group.label" :active="group.active">
                    <b-menu-item
                        v-show="item.label.toLocaleLowerCase('tr-TR').includes(filter.toLocaleLowerCase('tr-TR'))"
                        v-for="item in group.items"
                        :key="item.label"
                        :label="item.label"
                        :icon="item.icon"
                        :to="item.to"
                        tag="router-link"
                    >
                    </b-menu-item>
                </b-menu-item>
            </b-menu-list>
        </b-menu>
    </section>
</template>

<script>
export default {
    name: "Sidebar",
    components: {},
    data: () => ({
        filter: "",
        menu: {
            groups: [
                //  {
                //      label: "Menu",
                //      items: [
                //          {
                //              label: "Anasayfa",
                //              icon: "home",
                //              to: "/"
                //          }
                //      ]
                //  },
                {
                    active: false,
                    label: "Malzeme",
                    icon: "view-list",
                    items: [
                        {
                            label: "Malzeme Ekle",
                            icon: "plus",
                            to: "/malzeme/ekle"
                        },
                        {
                            label: "Malzeme Kayıtları",
                            icon: "view-list",
                            to: "/malzeme/liste"
                        },
                        {
                            label: "Envanter Kayıtları",
                            icon: "package-variant-closed",
                            to: "/envanter/liste"
                        },
                        {
                            label: "Stok Hareketleri",
                            icon: "dolly",
                            to: "/envanter/hareket"
                        },
                        {
                            label: "İrsaliye Ekle",
                            icon: "truck-check",
                            to: "/irsaliye/ekle"
                        },
                        {
                            label: "İrsaliye Kayıtları",
                            icon: "truck",
                            to: "/irsaliye/liste"
                        }
                    ]
                },
                //{
                //    active: false,
                //    label: "Envanter",
                //    items: []
                //},
                {
                    active: false,
                    label: "Müşteri / Tedarikçi",
                    icon: "account-multiple",
                    items: [
                        {
                            label: "Müşteri/Tedarikçi Ekle",
                            icon: "account-plus",
                            to: "/musteri/ekle"
                        },
                        {
                            label: "Müşteri/Tedarikçi Kayıtları",
                            icon: "account-multiple",
                            to: "/musteri/liste"
                        }
                    ]
                },
                {
                    active: false,
                    label: "Satınalma",
                    icon: "cart",
                    items: [
                        {
                            label: "Satınalma Talebi Ekle",
                            icon: "basket-plus",
                            to: "/satinalma/talep/ekle"
                        },
                        {
                            label: "Satınalma Talebi Kayıtları",
                            icon: "basket",
                            to: "/satinalma/talep/liste"
                        },
                        {
                            label: "Satınalma Siparişi Ekle",
                            icon: "cart-arrow-right",
                            to: "/satinalma/siparis/ekle"
                        },
                        {
                            label: "Satınalma Siparişi Kayıtları",
                            icon: "cart",
                            to: "/satinalma/siparis/liste"
                        }
                    ]
                },
                {
                    active: false,
                    label: "Satış",
                    icon: "chart-bar",
                    items: [
                        {
                            label: "Satış Siparişi Ekle",
                            icon: "barcode",
                            to: "/satis/siparis/ekle"
                        },
                        {
                            label: "Satış Siparişi Kayıtları",
                            icon: "chart-bar",
                            to: "/satis/siparis/liste"
                        }
                    ]
                },
                {
                    active: false,
                    label: "Üretim",
                    icon: "cogs",
                    items: [
                        {
                            label: "İş Emri Ekle",
                            icon: "book-plus",
                            to: "/uretim/isemri/ekle"
                        },
                        {
                            label: "İş Emri Kayıtları",
                            icon: "cogs",
                            to: "/uretim/isemri/liste"
                        },
                        {
                            label: "Üretim Onayı Ekle",
                            icon: "coolant-temperature",
                            to: "/uretim/onay/ekle"
                        },
                        {
                            label: "Üretim Onayı Kayıtları",
                            icon: "boom-gate",
                            to: "/uretim/onay/liste"
                        }
                    ]
                },
                {
                    active: false,
                    label: "Laboratuvar",
                    icon: "beaker",
                    items: [
                        {
                            label: "Renk Ekle",
                            icon: "flask-empty-plus",
                            to: "/lab/renk/ekle"
                        },
                        {
                            label: "Renk Kayıtları",
                            icon: "flask-empty",
                            to: "/lab/renk/liste"
                        },
                        {
                            label: "Numune Ekle",
                            icon: "beaker-plus",
                            to: "/lab/numune/ekle"
                        },
                        {
                            label: "Numune Kayıtları",
                            icon: "beaker",
                            to: "/lab/numune/liste"
                        }
                    ]
                }
            ]
        }
    }),
    methods: {}
};
</script>

<style>
.sidebar {
    height: 100vh;
    overflow-y: scroll;
}
.maxwi {
    max-width: 300px !important;
}
::-webkit-scrollbar {
    width: 1px;
}
</style>
