<template>
    <b-autocomplete
        placeholder="Malzeme seçiniz.."
        field="code"
        icon="search"
        size="is-small"
        v-model="value.code"
        :data="materials"
        :loading="$apollo.queries.readMaterial.loading"
        :disabled="disabled"
        @select="materials_select"
        @typing="materials_read"
        clearable
    >
        <template slot="empty">Sonuç bulunamadı.</template>

        <template slot="header">
            <a @click="materials_new">
                <span>Yeni Ekle</span>
            </a>
        </template>

        <template slot-scope="props">
            <div class="media">
                <!--
				<div class="media-left pt-2">
					<img width="45" src="@/assets/zeb.png" />
				</div>
				-->
                <div class="media-content">
                    {{ props.option.code }}
                    <br />
                    <small>
                        {{ props.option.name }} ~
                        <b>{{ props.option.class }}</b>
                    </small>
                </div>
            </div>
        </template>
    </b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "MaterialAutofill",
    props: ["value", "disabled"],
    data: () => ({
        materials: []
    }),
    methods: {
        materials_new() {
            this.$store.state.ui.material_modal = true;
        },
        materials_read() {
            this.$apollo.queries.readMaterial.start();
        },
        materials_select(opt) {
            Object.assign(this.value, opt);
            this.$store.state.ui.flush = this.$store.state.ui.flush + 1;
        }
    },
    apollo: {
        readMaterial: {
            query: gql`
                query readMaterials($config: String) {
                    readMaterials(config: $config) {
                        id
                        code
                        name
                        class
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 5,
                        sorts: [],
                        filters: {
                            namecode: "%" + this.value.code + "%"
                        }
                    })
                };
            },
            update(data) {
                this.materials = data.readMaterials;
            }
        }
    }
};
</script>
