import Vue from "vue";
import VueApollo from "vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";

Vue.use(VueApollo);

const defaultOptions = {
    tokenName: "token",
    httpEndpoint: "https://api.zebrapolimer.com",
    ssr: false,
    wsEndpoint: null,
    websocketsOnly: false,

    cache: new InMemoryCache({
        addTypename: false,
    }),
};

export function createProvider(options = {}) {
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options,
    });
    apolloClient.wsClient = wsClient;

    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                skip: true,
                debounce: 300,
                fetchPolicy: "network-only",
            },
        },
        errorHandler(error) {
			this.$buefy.snackbar.open({
				queue: false,
				type: "is-danger",
				message: error.message.replace("GraphQL error: @", ""),
				position: "is-bottom-right",
			});
        },
    });

    return apolloProvider;
}

export async function onLogin() {}
export async function onLogout() {}
