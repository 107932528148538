<template>
    <section>
        <div class="columns is-centered">
            <div class="column is-3">
                <img src="@/assets/logo.png" />
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <h4 class="title">
                            <center>Zebra Cloud</center>
                        </h4>
                    </div>

                    <form class="columns is-multiline">
                        <b-field class="column is-12" label="Kullanıcı Adı">
                            <b-input v-model="user.email"></b-input>
                        </b-field>
                        <b-field class="column is-12" label="Parola">
                            <b-input size="is-small" type="password" v-model="user.password" password-reveal></b-input>
                        </b-field>
                        <div class="column is-12">
                            <b-button type="is-light" @click="user_login" expanded>Giriş</b-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "Login",
    data() {
        return {
            user: {},
        };
    },
    methods: {
        user_login() {
            this.$apollo.queries.loginUser.start();
            this.$apollo.queries.loginUser.refetch();
        },
    },
    apollo: {
        loginUser: {
            query: gql`
                query loginUser($user: String) {
                    loginUser(user: $user) {
                        id
                        token
                        name
                        email
                        phone
                        perms {
                            id
                            name
                        }
                    }
                }
            `,
            variables() {
                this.$apollo.queries.loginUser.stop();
                return {
                    user: JSON.stringify(this.user),
                };
            },
            update(data) {
                this.$apollo.queries.loginUser.stop();
                localStorage.id = data.loginUser.id;
                localStorage.name = data.loginUser.name;
                localStorage.email = data.loginUser.email;
                localStorage.token = data.loginUser.token;
                this.$router.go(0);
            },
        },
    },
};
</script>

<style scoped>
.title {
    font-size: 20px;
    padding-bottom: 20px;
}
</style>
