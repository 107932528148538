<template>
    <section>
        <div class="container maxw">
            <div v-if="!$store.state.user.token" class="column is-12">
                <Login />
            </div>
            <div v-if="$store.state.user.token" class="columns">
                <div class="column is-3 p-0 m-0">
                    <Sidebar />
                </div>
                <div class="column is-9">
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
            <b-modal :active.sync="$store.state.ui.material_modal">
                <MaterialDetails />
            </b-modal>
            <b-modal :active.sync="$store.state.ui.customer_modal">
                <CustomerDetails />
            </b-modal>
        </div>
    </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Login from "@/components/Login.vue";
import MaterialDetails from "@/components/MaterialDetails.vue";
import CustomerDetails from "@/components/CustomerDetails.vue";

export default {
    components: {
        Sidebar,
        Login,
        MaterialDetails,
        CustomerDetails
    },
    data: () => ({}),
    methods: {},
    watch: {
        $route(to) {
            document.title = to.meta.title || "Zebra Polimer";
        }
    }
};
</script>

<style>
body {
    color: #000 !important;
    cursor: pointer !important;
    font: 12px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
}
.maxw {
    max-width: 1600px !important;
}
.column {
    margin: 4px 0 4px 0 !important;
    padding: 0 1px 0 1px !important;
}
.columns {
    margin: 0px !important;
    padding: 0px !important;
}

.box {
    padding: 20px !important;
    box-shadow: 0 0 0 0px #d1d1d166 !important;
}
/*.field:not(:last-child){margin:0px !important;}*/
select {
    appearance: none;
    margin-right: 0px;
}

button {
    height: 25px !important;
    font: 12px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
}

input,
textarea,
select {
    color: black !important;
    border: none !important;
    background-color: #d1d1d132 !important;
    height: 25px !important;
    font: 12px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
}
.b-numberinput input {
    text-align: unset !important;
}
.field.is-floating-label.has-numberinput .label {
    margin-left: unset !important;
}

.tabs {
    font: 12px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji" !important;
}

.select:not(.is-multiple) {
    height: unset !important;
}
textarea {
    height: initial !important;
}

h1,
h2,
h3 {
    line-height: 1.2 !important;
}

.modal-background {
    background-color: #ffffff !important;
}
.rel {
    position: relative;
}
</style>
