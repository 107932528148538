import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "../views/Homepage.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Homepage",
		component: Homepage,
	},
	{
		path: "/malzeme/liste",
		meta: { title: "Malzeme Kayıtları | Zebra Polimer" },
		component: () => import("../components/MaterialTable.vue"),
	},
	{
		path: "/malzeme/ekle",
		meta: { title: "Malzeme Detayları | Zebra Polimer" },
		component: () => import("../components/MaterialDetails.vue"),
	},
	{
		path: "/malzeme/detay",
		meta: { title: "Malzeme Detayları | Zebra Polimer" },
		component: () => import("../components/MaterialDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/envanter/liste",
		meta: { title: "Envanter Kayıtları | Zebra Polimer" },
		component: () => import("../components/StockTable.vue"),
	},
	{
		path: "/envanter/hareket",
		meta: { title: "Stok Hareketleri | Zebra Polimer" },
		component: () => import("../components/StockMovementTable.vue"),
	},
	{
		path: "/irsaliye/ekle",
		meta: { title: "İrsaliye Ekle | Zebra Polimer" },
		component: () => import("../components/InvoiceDetails.vue"),
	},
	{
		path: "/irsaliye/detay",
		meta: { title: "İrsaliye Detayları | Zebra Polimer" },
		component: () => import("../components/InvoiceDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/irsaliye/liste",
		meta: { title: "İrsaliye Kayıtları | Zebra Polimer" },
		component: () => import("../components/InvoiceTable.vue"),
	},
	{
		path: "/musteri/ekle",
		meta: { title: "Müşteri/Tedarikçi Ekle | Zebra Polimer" },
		component: () => import("../components/CustomerDetails.vue"),
	},
	{
		path: "/musteri/detay",
		meta: { title: "Müşteri/Tedarikçi Detayları | Zebra Polimer" },
		component: () => import("../components/CustomerDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/musteri/liste",
		meta: { title: "Müşteri/Tedarikçi Kayıtları | Zebra Polimer" },
		component: () => import("../components/CustomerTable.vue"),
	},
	{
		path: "/satinalma/talep/ekle",
		meta: { title: "Satınalma Talebi Ekle | Zebra Polimer" },
		component: () => import("../components/PurchaseRequestDetails.vue"),
	},
	{
		path: "/satinalma/talep/detay",
		meta: { title: "Satınalma Talebi Detayları | Zebra Polimer" },
		component: () => import("../components/PurchaseRequestDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/satinalma/talep/liste",
		meta: { title: "Satınalma Talebi Kayıtları | Zebra Polimer" },
		component: () => import("../components/PurchaseRequestTable.vue"),
	},
	{
		path: "/satinalma/siparis/ekle",
		meta: { title: "Satınalma Siparişi Ekle | Zebra Polimer" },
		component: () => import("../components/PurchaseOrderDetails.vue"),
	},
	{
		path: "/satinalma/siparis/detay",
		meta: { title: "Satınalma Siparişi Detayları | Zebra Polimer" },
		component: () => import("../components/PurchaseOrderDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/satinalma/siparis/liste",
		meta: { title: "Satınalma Siparişi Kayıtları | Zebra Polimer" },
		component: () => import("../components/PurchaseOrderTable.vue"),
	},
	{
		path: "/satis/siparis/ekle",
		meta: { title: "Satış Siparişi Ekle | Zebra Polimer" },
		component: () => import("../components/SaleOrderDetails.vue"),
	},
	{
		path: "/satis/siparis/detay",
		meta: { title: "Satış Siparişi Detayları | Zebra Polimer" },
		component: () => import("../components/SaleOrderDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/satis/siparis/liste",
		meta: { title: "Satış Siparişi Kayıtları | Zebra Polimer" },
		component: () => import("../components/SaleOrderTable.vue"),
	},
	{
		path: "/uretim/isemri/ekle",
		meta: { title: "İş Emri Ekle | Zebra Polimer" },
		component: () => import("../components/ProductionOrderDetails.vue"),
	},
	{
		path: "/uretim/isemri/detay",
		meta: { title: "İş Emri Detayları | Zebra Polimer" },
		component: () => import("../components/ProductionOrderDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/uretim/isemri/liste",
		meta: { title: "İş Emri Kayıtları | Zebra Polimer" },
		component: () => import("../components/ProductionOrderTable.vue"),
	},
	{
		path: "/uretim/onay/ekle",
		meta: { title: "Üretim Onayı Ekle | Zebra Polimer" },
		component: () => import("../components/ProductionConfirmDetails.vue"),
	},
	{
		path: "/uretim/onay/detay",
		meta: { title: "Üretim Onayı Detayları | Zebra Polimer" },
		component: () => import("../components/ProductionConfirmDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/uretim/onay/liste",
		meta: { title: "Üretim Onayı Kayıtları | Zebra Polimer" },
		component: () => import("../components/ProductionConfirmTable.vue"),
	},
	{
		path: "/lab/renk/ekle",
		meta: { title: "Renk Ekle | Zebra Polimer" },
		component: () => import("../components/ColorDetails.vue"),
	},
	{
		path: "/lab/renk/detay",
		meta: { title: "Renk Detayları | Zebra Polimer" },
		component: () => import("../components/ColorDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/lab/renk/liste",
		meta: { title: "Renk Kayıtları | Zebra Polimer" },
		component: () => import("../components/ColorTable.vue"),
	},
	{
		path: "/lab/numune/ekle",
		meta: { title: "Numune Ekle | Zebra Polimer" },
		component: () => import("../components/SampleDetails.vue"),
	},
	{
		path: "/lab/numune/detay",
		meta: { title: "Numune Detayları | Zebra Polimer" },
		component: () => import("../components/SampleDetails.vue"),
		query: { modi: "edit" },
	},
	{
		path: "/lab/numune/liste",
		meta: { title: "Numune Kayıtları | Zebra Polimer" },
		component: () => import("../components/SampleTable.vue"),
	},
];

const router = new VueRouter({
	//mode: //"history",
	//base: process.env.BASE_URL,
	routes,
});

export default router;
