<template>
	<section>
		<div class="columns is-multiline">
			<b-field class="column is-6 box" label="Genel" grouped group-multiline>
				<b-field class="column is-12" label="Oluşturan">
					<b-input v-model="meta.createdby.name" disabled></b-input>
				</b-field>
				<b-field class="column is-12" label="Oluşturma Tarihi">
					<b-input v-model="meta.createdat" disabled></b-input>
				</b-field>
			</b-field>
			<b-field class="column is-6 box" label="Durum" grouped group-multiline>
				<b-field class="column is-12">
					<b-switch
						type="is-danger"
						:rounded="false"
						:value="meta.disabled"
						disabled
					>
						İnaktif
					</b-switch>
				</b-field>
				<b-field class="column is-12">
					<b-switch
						type="is-warning"
						:rounded="false"
						:value="meta.archived"
						disabled
					>
						Arşivli
					</b-switch>
				</b-field>
			</b-field>
		</div>

		<b-field class="columns box mt-2" label="Kayıt Geçmişi" grouped group-multiline>
			<b-table
				class="column is-12"
				:mobile-cards="false"
				:data="updates.data"
				:total="updates.total"
				:current-page.sync="updates.page"
				:loading="$apollo.queries.readUpdate.loading"
				backend-pagination
				striped
				hoverable
				narrowed
				paginated
				detailed
				custom-detail-row
				pagination-simple
			>
				<template slot="empty">
					<div class="is-12 has-text-centered">Kayıt geçmişi bulunamadı.</div>
				</template>

				<template slot="detail" slot-scope="props">
					<json-tree :level="1" :data="props.row.diff"></json-tree>
				</template>

				<b-table-column field="id" label="ID" v-slot="props">{{
					props.row.id.slice(-5)
				}}</b-table-column>
				<b-table-column field="table" label="Açıklama" v-slot="props">{{
					props.row.name
				}}</b-table-column>
				<b-table-column field="row" label="Row" v-slot="props">{{
					props.row.id_row.slice(-4)
				}}</b-table-column>
				<b-table-column field="table" label="Table" v-slot="props">{{
					props.row.table
				}}</b-table-column>
				<b-table-column
					field="updatedat"
					label="Değiştirme Tarihi"
					v-slot="props"
					>{{
						new Date(props.row.updatedat).toLocaleDateString("tr-TR", {
							weekday: "long",
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "numeric",
							minute: "numeric",
						})
					}}</b-table-column
				>
				<b-table-column field="updatedby" label="Değiştiren" v-slot="props">{{
					props.row.updatedby.name
				}}</b-table-column>
			</b-table>
		</b-field>
	</section>
</template>

<script>
import Vue from "vue";
import gql from "graphql-tag";
import JsonTree from "vue-json-tree";

Vue.component("json-tree", JsonTree);

export default {
	name: "MetaDetails",
	component: {
		JsonTree,
	},
	data: () => ({
		meta: {
			createdby: {},
		},
		updates: {
			page: 1,
			take: 20,
			total: 9999,
			data: [],
		},
	}),
	created() {
		this.id = this.$route.query.id || "";
		this.$apollo.queries.readMeta.start();
		this.$apollo.queries.readUpdate.start();
	},
	apollo: {
		readMeta: {
			query: gql`
				query readMeta($config: String) {
					readMeta(config: $config) {
						id
						disabled
						archived
						createdat
						createdby {
							id
							name
						}
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						take: 1,
						skip: 0,
						sorts: [],
						filters: { id_row: this.id },
					}),
				};
			},
			update(data) {
				this.$apollo.queries.readMeta.stop();
				this.meta = data.readMeta;
				this.meta.createdat = new Date(this.meta.createdat).toLocaleDateString(
					"tr-TR",
					{
						weekday: "long",
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					}
				);
			},
		},
		readUpdate: {
			query: gql`
				query readUpdates($config: String) {
					readUpdates(config: $config) {
						id
						id_row
						name
						table
						updatedat
						updatedby {
							id
							name
						}
						diff
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						sorts: [],
						take: this.updates.take,
						filters: { id_row: this.id },
						skip: (this.updates.page - 1) * this.updates.take,
					}),
				};
			},
			update(data) {
				this.$apollo.queries.readUpdate.stop();
				this.updates.data = data.readUpdates;
			},
		},
	},
};
</script>
