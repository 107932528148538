import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { createProvider } from "@/vue-apollo";


import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "@mdi/font/css/materialdesignicons.min.css";
import excel from 'vue-excel-export'
 

Vue.use(Buefy, {
	defaultFieldLabelPosition: "on-border",
});
Vue.use(excel)

new Vue({
	router,
	store,
	render: (h) => h(App),
	apolloProvider: createProvider(),
}).$mount("#app");
Vue.config.productionTip = false;
