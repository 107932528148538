<template>
    <section>
        <TitleBar title="Müşteri/Tedarikçi Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanim" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="customer.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Unvan">
                        <b-input v-model="customer.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Firma Temsilcisi">
                        <b-input v-model="customer.firmrep" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Müşteri Temsilcisi">
                        <UserAutofill v-model="customer.cusrep" :disabled="modi == 'show'" />
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Hiyerarşi" grouped group-multiline>
                    <b-field class="column is-12" label="Müşteri/Tedarikçi Sınıfı">
                        <b-select placeholder="Seçiniz.." v-model="customer.class" :disabled="modi == 'show'" expanded>
                            <option value="Aday">Aday</option>
                            <option value="Tedarikçi">Tedarikçi</option>
                            <option value="Müşteri">Müşteri</option>
                            <option value="Herikisi">Herikisi</option>
                            <option value="Diğer">Diğer</option>
                        </b-select>
                    </b-field>
                    <b-field class="column is-12" label="Müşteri/Tedarikçi Ana Grubu">
                        <b-input v-model="customer.maingroup" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Vergi No">
                        <b-input v-model="customer.taxnum" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Vergi Dairesi">
                        <b-input v-model="customer.taxadm" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns is-multiline" label="İletişim">
                <b-field class="column is-6 box" label="İletişim" grouped group-multiline>
                    <b-field class="column is-12" label="E-Mail">
                        <b-input v-model="customer.email" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Telefon">
                        <b-input v-model="customer.phone" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Mobil">
                        <b-input v-model="customer.mobile" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Fax">
                        <b-input v-model="customer.fax" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Adres" grouped group-multiline>
                    <b-field class="column is-12" label="Sevk Adresi">
                        <b-input v-model="customer.shipaddr" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Fatura Adresi">
                        <b-input v-model="customer.invoaddr" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns" label="Notlar">
                <b-field class="column is-12 box" label="Notlar">
                    <b-input class="column is-12" type="textarea" v-model="customer.note" :rows="5" :disabled="modi == 'show'"></b-input>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Geçmişi" :disabled="!customer.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="customer_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="İptal" @click="customer_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import UserAutofill from "@/components/UserAutofill.vue";
import TitleBar from "@/components/TitleBar.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "CustomerDetails",
    components: {
        TitleBar,
        UserAutofill,
        MetaDetails,
    },
    data: () => ({
        modi: "",
        customer: {
            id: "",
            cusrep: {},
        },
    }),
    created() {
        this.customer.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.customer_read();
    },
    methods: {
        customer_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readCustomer.start();
        },
        customer_save() {
            var err = "";
            var payload = this.customer;

            if (!payload.name) err = "Unvan boş olamaz.";
            if (!payload.class) err = "Müşteri/Tedarikçi sınıfı boş olamaz.";
            if (!payload.cusrep) err = "Müşteri/Tedarikçi temsilcisi boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right",
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($customer: String) {
                            saveCustomer(customer: $customer)
                        }
                    `,
                    variables: {
                        customer: JSON.stringify(payload),
                    },
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right",
                    });

                    if (this.$store.state.ui.customer_modal) return (this.$store.state.ui.customer_modal = false);
                    else return this.$router.push({ path: "/musteri/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right",
                    });
                });
        },
        customer_cancel() {
            if (this.$store.state.ui.customer_modal) return (this.$store.state.ui.customer_modal = false);
            this.$router.push({ path: "/musteri/liste" });
        },
    },
    apollo: {
        readCustomer: {
            query: gql`
                query readCustomers($config: String) {
                    readCustomers(config: $config) {
                        id
                        name
                        class
                        maingroup
                        cusrep {
                            id
                            name
                        }
                        firmrep
                        taxnum
                        taxadm
                        shipaddr
                        invoaddr
                        email
                        phone
                        mobile
                        fax
                        note
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.customer.id },
                    }),
                };
            },
            update(data) {
                this.$apollo.queries.readCustomer.stop();
                this.customer = data.readCustomers[0];
                if (this.modi == "ref") {
                    delete this.customer.id;
                }
            },
        },
    },
};
</script>
