<template>
    <section>
        <TitleBar title="Malzeme Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="material.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Malzeme Kodu">
                        <b-input v-model="material.code" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Malzeme Açıklaması">
                        <b-input v-model="material.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Hiyerarşi" grouped group-multiline>
                    <b-field class="column is-12" label="Malzeme Sınıfı">
                        <b-select placeholder="Seçiniz.." v-model="material.class" :disabled="modi == 'show'" expanded>
                            <option value="Hammadde">Hammadde</option>
                            <option value="Yarı Mamül">Yarı Mamül</option>
                            <option value="Mamül">Mamül</option>
                            <option value="Ticari">Ticari</option>
                            <option value="Diğer">Diğer</option>
                        </b-select>
                    </b-field>
                    <b-field class="column is-12" label="Malzeme Ana Grup">
                        <b-input v-model="material.maingroup" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Malzeme Alt Grup">
                        <b-input v-model="material.subgroup" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Teknik" disabled> </b-tab-item>
            <b-tab-item label="Müşteri" disabled></b-tab-item>

            <b-tab-item class="columns" label="Envanter">
                <b-field class="column is-12 box" label="Malzeme Stokları Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" disabled />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table v-if="material.stock" class="column is-12" :key="$store.state.ui.flush" :data="material.stock.lots" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Malzeme stok kaydı bulunamadı.
                            </div>
                        </template>
                        <b-table-column field="id" label="ID" subheading="Toplam" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="material.code" label="Malzeme Kodu">{{ material.stock.material.code }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>{{ props.row.lotnum }}</b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" :subheading="material.stock.lots.reduce((a, c) => a + c.quantity, 0) + ' KG'" numeric sortable>{{
                            props.row.quantity
                        }}</b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns" label="Stok Hareketleri">
                <b-field class="column is-12 box" label="Malzeme Stok Hareketleri Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" disabled />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="material.movements" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Malzeme stok hareketi kaydı bulunamadı.
                            </div>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="class" label="Hareket Türü" v-slot="props" sortable>{{ props.row.class }}</b-table-column>
                        <b-table-column field="name" label="Hareket Açıklaması" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                        <b-table-column field="material.code" label="Malzeme Kodu" v-slot="props" sortable>{{ props.row.material.code }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>{{ props.row.lotnum }}</b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" numeric sortable>{{ props.row.quantity }} KG</b-table-column>
                        <b-table-column field="timestamp" label="Tarih" v-slot="props" sortable>{{
                            new Date(props.row.timestamp).toLocaleDateString("tr-TR", {
                                weekday: "long",
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                            })
                        }}</b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Numune" disabled></b-tab-item>
            <b-tab-item label="Kalite" disabled></b-tab-item>

            <b-tab-item class="columns" label="BOM">
                <b-field class="column is-12 box" label="Ürün Ağacı Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>
                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="material.boms" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Ürün ağacı kaydı bulunamadı.
                            </div>
                        </template>
                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" sortable>
                            <b-numberinput
                                type="is-link is-light"
                                placeholder="0.00"
                                controls-alignment="right"
                                controls-position="compact"
                                v-model="props.row.quantity"
                                :min-step="0.001"
                                :disabled="modi == 'show'"
                                expanded
                            ></b-numberinput>
                        </b-table-column>
                        <b-table-column field="name" label="Aksiyon" v-slot="props" sortable>
                            <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'"
                        /></b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="ROU" disabled></b-tab-item>

            <b-tab-item class="columns" label="Notlar">
                <b-field class="column is-12 box" label="Notlar">
                    <b-input class="column is-12" type="textarea" v-model="material.note" :rows="5" :disabled="modi == 'show'"></b-input>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Gecmisi" :disabled="!material.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="material_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="Iptal" @click="material_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "MaterialDetails",
    components: {
        TitleBar,
        MaterialAutofill,
        MetaDetails
    },
    data: () => ({
        modi: "",
        material: {
            id: "",
            boms: [],
            stock: {
                material: {},
                lots: []
            }
        }
    }),
    created() {
        this.material.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.material_read();
    },
    methods: {
        material_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readMaterials.start();
        },
        material_save() {
            var err = "";
            var payload = this.material;
            delete payload.stock;
            delete payload.movements;

            if (!payload.code) err = "Malzeme kodu boş olamaz.";
            if (!payload.name) err = "Malzeme açıklaması boş olamaz.";
            if (!payload.class) err = "Malzeme sınıfı boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right"
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($material: String) {
                            saveMaterial(material: $material)
                        }
                    `,
                    variables: {
                        material: JSON.stringify(payload)
                    }
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right"
                    });

                    if (this.$store.state.ui.material_modal) return (this.$store.state.ui.material_modal = false);
                    else return this.$router.push({ path: "/malzeme/liste" });
                })
                .catch((error) => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: error.message.replace("GraphQL error: @", ""),
                        position: "is-bottom-right"
                    });
                });
        },
        material_cancel() {
            if (this.$store.state.ui.material_modal) return (this.$store.state.ui.material_modal = false);
            this.$router.push({ path: "/malzeme/liste" });
        },
        bom_add() {
            this.material.boms.push({
                id: "",
                item: {},
                quantity: 0.0
            });
        },
        bom_delete(id, index) {
            if (!id) this.material.boms.splice(index, 1);
            else;
        }
    },
    apollo: {
        readMaterials: {
            query: gql`
                query readMaterials($config: String) {
                    readMaterials(config: $config) {
                        id
                        code
                        name
                        class
                        maingroup
                        subgroup
                        note
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                            lotnum
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.material.id }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readMaterials.stop();
                this.material = data.readMaterials[0];
                this.$apollo.queries.readMaterialStocks.start();
                this.$apollo.queries.readStockMovements.start();
                if (this.modi == "ref") {
                    delete this.material.id;
                    delete this.material.stock;
                    delete this.material.movements;
                    for (var bom of this.material.boms) delete bom.id;
                }
                this.$store.state.ui.flush++;
            }
        },
        readMaterialStocks: {
            query: gql`
                query readMaterialStocks($config: String) {
                    readMaterialStocks(config: $config) {
                        id
                        material {
                            code
                        }
                        lots {
                            id
                            lotnum
                            quantity
                        }
                        quantity
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 20,
                        sorts: [],
                        filters: {
                            material: { id: this.material.id }
                        }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readMaterialStocks.stop();
                this.material.stock = data.readMaterialStocks[0];
                this.$store.state.ui.flush++;
            }
        },
        readStockMovements: {
            query: gql`
                query readStockMovements($config: String) {
                    readStockMovements(config: $config) {
                        id
                        class
                        name
                        material {
                            id
                            code
                            class
                            name
                        }
                        lotnum
                        quantity
                        id_source
                        sourcetable
                        timestamp
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 20,
                        sorts: [],
                        filters: {
                            id_material: this.material.id
                        }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readStockMovements.stop();
                this.material.movements = data.readStockMovements;
                this.$store.state.ui.flush++;
            }
        }
    }
};
</script>
